<template>
	<div>
		<slot :share="share">
			<div
				class="relative flex justify-center w-10 h-10 bg-gray-500 rounded-full shadow-md cursor-pointer"
				@click="share"
			>
				<img
					:src="requireIcon(shareIcon)"
					alt="social share"
					height="20"
					width="20"
				>
			</div>
			<div class="text-sm font-semibold">
				Share
			</div>
		</slot>
	</div>
</template>

<script defer>
import { mapMutations } from 'vuex'

import { SUCCESS } from '@/constants/toast/type.js'
import { logError } from '@/utils/error-handling.js'
import requireIcon from '@/utils/requireIcon.js'

export default {
	props: {
		shareIcon: {
			type: String,
			default: 'share-white.svg'
		}
	},
	data: function () {
		return {
			doesShare: null,
			isMount: false
		}
	},
	computed: {
		shareObject: function () {
			if (this.isMount !== true) {
				return {
					url: '', title: '', text: ''
				}
			} // just return empty object set if not mounted [for ssr]

			const currentUrl = window.location.href
			const currentTitle = document.title || 'Check this out' // REVIEW this is required for windows share to not fail with DOMException error code 20
			return {
				url: currentUrl,
				title: currentTitle,
				text: currentTitle
			}
		}
	},
	mounted() {
		this.doesShare = typeof navigator !== 'undefined' && typeof navigator.share === 'function'
		this.isMount = true
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		async share() {
			if (this.doesShare === null) return
			if (this.doesShare) {
				try {
					await navigator.share({
						title: this.shareObject.title, text: this.shareObject.text, url: this.shareObject.url
					})
				} catch (e) {
					logError('SHARE FAIL: ', e)
				}
			} else {
				try {
					await navigator.clipboard.writeText(this.shareObject.text + ' ' + this.shareObject.url)
					this.showToast({
						primaryText: 'Copied to clipboard',
						type: SUCCESS
					})
				} catch (e) {
					logError('CLIPBOARD FAIL: ', e)
				}
			}
		},
		requireIcon: requireIcon
	}
}
</script>
